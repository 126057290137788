import React, { useEffect, useState } from 'react'
import { delete_customer, fetch_customers, update_customer } from '../../common/requests/customers';
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';


const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

export default function Edit_Customer() {

  const navigate = useNavigate();

  const {id: customerId} = useParams();

  const user = sessionStorage.getItem(authenticatedUserToken) 
    ? JSON.parse(sessionStorage.getItem(authenticatedUserToken))
    : null;
  
  const [customer, setCustomer] = useState(null);
  const [refetch, triggerRefetch] = useState(false);
  const [editShow, toggleEdit] = useState(null);
  const [editValues, setEditValues] = useState({
    name: null,
    email: null,
  })

  const handleUpdate = (type) => {

    const query = {_id: customer?._id};
    let payload = {};
    if ( type === "name" ) payload.name = editValues.name;
    else if ( type === "email" ) payload.email = editValues.email;

    update_customer(query, payload, customer?.profileId)
      .then((res) => {
        console.log(res);
        triggerRefetch(!refetch);
      }).catch((err) => alert("Something went wrong"));
  }

  const handleDelete = () => {

    delete_customer({_id: customer?._id}, customer?.profileId)
      .then((res) => {
        console.log(res);
        navigate(-1)
      }).catch((err) => alert("Something went wrong"));
  }


  useEffect(() => {
    const userIdParam = user.roleId?.name === "superadmin" ? undefined : user.sub;
    fetch_customers({customerId, userId: userIdParam})
    .then((res) => {
      setCustomer(res.data?.customers?.[0]);
    }).catch((err) => alert("Something went wrong"));
  },[refetch]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Edit Customer</h3>
          <h5>{customer ? <></> : "Loading customer data"}</h5>
          {customer && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Label
                style={{ cursor: "pointer" }}
                onClick={() => toggleEdit("name")}
              >
                Full Name: {customer?.name}
              </Form.Label>
              {editShow === "name" && (
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Update name"
                    onChange={(e) =>
                      setEditValues((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleUpdate("name")}
                  >
                    Submit
                  </Button>
                </div>
              )}
              <Form.Label
                style={{ cursor: "pointer" }}
                onClick={() => toggleEdit("email")}
              >
                Email: {customer?.email}
              </Form.Label>
              {editShow === "email" && (
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Update email"
                    onChange={(e) =>
                      setEditValues((prev) => ({ ...prev, email: e.target.value }))
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleUpdate("email")}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          )}
          <Button
            className='mt-5'
            variant="warning"
            onClick={() => handleDelete()}
          >
            Delete Customer
          </Button>
        </Col>
      </Row>
    </Container>
  );
}