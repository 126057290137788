import axios from "axios";
import Domains from "../../config/domains";

const backendURI = Domains.backend;

export function fetch_profiles(args){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/profiles/fetch`;
        const params = {
            profileId: args?.profileId,
            userId: args?.userId, 
            email: args?.email, 
            name: args?.name,
        };
        const options = { withCredentials: true }

        axios.get(url, { params, ...options })
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function create_profile(payload){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/profiles/create`;
        const options = { withCredentials: true }
        const body = {payload};

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function update_profile(query, payload, userId){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/profiles/update`;
        const options = { withCredentials: true }
        const body = {query, payload, userId};

        axios.patch(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function delete_profile(query){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/profiles/delete`;
        const options = { withCredentials: true }
        const body = {query};

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}