import React, { useEffect, useState } from 'react';
import { fetch_pages } from '../../common/requests/static';

export default function Static() {

  const [html, setHtml] = useState(null);

  useEffect(() => {
    fetch_pages()
    .then((res) => {
      setHtml(res.data?.pages?.[0]?.html);
    }).catch((err) => alert("Something went wrong"));
  },[]);

  // Render the static HTML using dangerouslySetInnerHTML
  return (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );
}
