import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { fetch_profiles } from '../../common/requests/profile';
import { create_customer } from '../../common/requests/customers';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';

const Bullet = styled.li`
    margin-top: .3rem;
`

const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

export default function Admin() {

  const navigate = useNavigate();
  const [showAddCustomer, toggleShowAddCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    name: null,
    email: null,
    profileId: null,
  }) 
  const [users, setUsers] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if ( !newCustomer?.profileId ) newCustomer.profileId = users?.[0]?._id
    create_customer(newCustomer)
      .then((res) => {
        console.log(res);
      }).catch((err) => alert("Something went wrong"));
  };

  useEffect(() => {
    fetch_profiles()
    .then((res) => {
      setUsers(res.data?.profiles);
    }).catch((err) => alert("Something went wrong"));
  },[showAddCustomer]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Admin</h3>
          <ul>
            <Bullet><Button variant="primary" onClick={() => navigate('/admin/users')}>Users</Button></Bullet>
            <Bullet><Button variant="primary" onClick={() => navigate('/admin/customers')}>Customers</Button></Bullet>
            <Bullet><Button variant="primary" onClick={() => navigate('/edit-static/someId')}>Edit static</Button></Bullet>
          </ul>
          <ul>
            <li><Button variant="info" onClick={() => toggleShowAddCustomer(true)}>Add customer</Button></li>
          </ul>
          {showAddCustomer && users.length ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name:</Form.Label>
                <Form.Control type="text" onChange={(e) => setNewCustomer((prev) => ({ ...prev, name: e.target.value }))} />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" onChange={(e) => setNewCustomer((prev) => ({ ...prev, email: e.target.value }))} />
              </Form.Group>
              <Form.Group controlId="formProfileId">
                <Form.Label>Select User:</Form.Label>
                <Form.Control as="select" onChange={(e) => setNewCustomer((prev) => ({ ...prev, profileId: e.target.value }))}>
                  {users.map((user) => (
                    <option key={user?._id} value={user?._id}>{user?.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit">Add customer</Button>
            </Form>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}
