import axios from "axios";
import Domains from "../../config/domains";

const backendURI = Domains.backend;

export function fetch_pages(args){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/pages/fetch`;
        const params = {
            pageId: args?.pageId,
        };
        const options = { withCredentials: true }

        axios.get(url, { params, ...options })
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function create_page(payload){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/pages/create`;
        const options = { withCredentials: true }
        const body = {payload};

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function update_page(query, payload){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/pages/update`;
        const options = { withCredentials: true }
        const body = {query, payload};

        axios.patch(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}




export function delete_page(query){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/pages/delete`;
        const options = { withCredentials: true }
        const body = {query};

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}