import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { fetch_pages, update_page } from '../../common/requests/static';
import { useNavigate, useParams } from 'react-router-dom';

export default function Edit_Static() {

  const navigate = useNavigate();
  const {id: pageId} = useParams();

  const [editedHtml, setEditedHtml] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [pageIdParam, setPageIdParam] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    update_page({_id: pageIdParam}, {html: editedHtml})
      .then((res) => {
        console.log(res);
        setRefetch(!refetch)
      }).catch((err) => alert("Something went wrong"));
  };


  useEffect(() => {
    fetch_pages({_id: pageId})
    .then((res) => {
      setEditedHtml(res.data?.pages?.[0]?.html);
      setPageIdParam(res.data?.pages?.[0]?._id);
    }).catch((err) => alert("Something went wrong"));
  },[refetch]);

  return (
    <div>
      <h2>Edit Static HTML</h2>
      { editedHtml ? <form onSubmit={handleSubmit}>
        <ReactQuill
         style={{ backgroundColor: 'white' }}
          value={editedHtml}
          onChange={(value) => setEditedHtml(value)}
          theme="snow" // You can choose a different theme if you prefer
        />
        <br />
        <button type="submit">Submit Changes</button>
      </form> : null }
    </div>
  );
}
