import React, { useEffect, useState } from 'react'
import { fetch_customers } from '../../common/requests/customers';
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { useNavigate } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';


const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

export default function Customers() {

  const navigate = useNavigate();

  const user = sessionStorage.getItem(authenticatedUserToken) 
    ? JSON.parse(sessionStorage.getItem(authenticatedUserToken))
    : null;
  
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    fetch_customers({userId: user.sub})
    .then((res) => {
      setCustomers(res.data?.customers);
    }).catch((err) => alert("Something went wrong"));
  },[]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Customers</h3>
          <div style={{ display: 'flex' , justifyContent: 'space-around'}} ><p>Full Name</p><p>Email</p></div>
          {customers ? (
            <ListGroup>
              {customers.map((customer) => (
                <ListGroup.Item
                  key={customer?._id}
                  action
                  onClick={() => navigate(`/edit-customer/${customer?._id}`)}
                >
                  <Row>
                    <Col>{customer?.name}</Col>
                    <Col className='border-start'>{customer?.email}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}
