import React from 'react';
import { useNavigate } from 'react-router-dom';
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

const Bullet = styled.li`
    margin-top: .3rem;
`

const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

const Landing = () => {
    const navigate = useNavigate();

    const user = sessionStorage.getItem(authenticatedUserToken)
        ? JSON.parse(sessionStorage.getItem(authenticatedUserToken))
        : null;

    const role = user?.roleId?.name ? user.roleId.name : "Guest";

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <h3>Welcome {role}</h3>
                    <p className='mt-3'>This is the landing of the assignment.</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <ul>
                        <Bullet>
                            <Button variant="primary" onClick={() => navigate('/profile')}>My Profile</Button>
                        </Bullet>
                        <Bullet>
                            <Button variant="primary" onClick={() => navigate('/static')}>Static Page</Button>
                        </Bullet>
                        { role === "superadmin" ? <Bullet>
                            <Button variant="info" onClick={() => navigate('/admin')}>Admin</Button>
                        </Bullet> : null }
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Landing;
