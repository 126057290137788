import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { fetch_profiles } from '../../common/requests/profile';
import { create_customer } from '../../common/requests/customers';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

const Bullet = styled.li`
    margin-top: .3rem;
`
const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

export default function Profile() {

  const navigate = useNavigate();

  const user = sessionStorage.getItem(authenticatedUserToken) 
    ? JSON.parse(sessionStorage.getItem(authenticatedUserToken))
    : null;

  const [profile, setProfile] = useState(null);
  const [showAddCustomer, toggleShowAddCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    name: null,
    email: null,
    profileId: null,
  }) 

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    create_customer(newCustomer)
      .then((res) => {
        console.log(res);
      }).catch((err) => alert("Something went wrong"));
  };

  useEffect(() => {
    fetch_profiles({userId: user.sub})
    .then((res) => {
      const profile = res.data?.profiles?.[0];
      setProfile(profile); 
      setNewCustomer((prev) => ({...prev, profileId: profile?._id}))
    }).catch((err) => alert("Something went wrong"));
  },[]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>Profile</h3>
          <ul>
            <Bullet>
              <Button variant="primary" onClick={() => navigate(`/edit-profile/${profile?._id}`)}>Edit Profile</Button>
            </Bullet>
            <Bullet>
              <Button variant="primary" onClick={() => navigate('/customers')}>Customers</Button>
            </Bullet>
          </ul>
          <ul>
            <Bullet>
              <Button variant="info" onClick={() => toggleShowAddCustomer(true)}>Add Customer</Button>
            </Bullet>
          </ul>
        </Col>
      </Row>
      {showAddCustomer &&
        <Row className="mt-4">
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFullName">
                <Form.Label>Full Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  onChange={(e) => setNewCustomer((prev) => ({ ...prev, name: e.target.value }))} />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  onChange={(e) => setNewCustomer((prev) => ({ ...prev, email: e.target.value }))} />
              </Form.Group>
              <Button variant="primary" type="submit">Add Customer</Button>
            </Form>
          </Col>
        </Row>
      }
    </Container>
  );
}
