import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Guard } from "../authentication/guard";

import Login from "../../pages/auth/login";
import Register from "../../pages/auth/register";
import Landing from "../../pages/landing";
import styled from "styled-components";
import Navbar from "../../common/components/Navbar";
import Admin from "../../pages/admin";
import Static from "../../pages/static";
import Profile from "../../pages/profile";
import Users from "../../pages/users";
import Customers from "../../pages/customers";
import Edit_Profile from "../../pages/editProfile";
import Edit_Customer from "../../pages/editCustomer";
import Edit_Static from "../../pages/editStatic";
import Admin_Customers from "../../pages/adminCustomers";
import '../../App.css';

const Page = styled.div``;

const AppRouter = () => {

    
    return (
        <Page className="App">
            <Navbar />
            <Router>
                <Routes>
                    { /* ROOT */ }
                    <Route path="/" element={<Landing />} />

                    { /* AUTH */ }
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    { /* APP */ }
                    <Route path="/admin" element={<Guard><Admin /></Guard>} />
                    <Route path="/static" element={<Guard><Static /></Guard>} />
                    <Route path="/profile" element={<Guard><Profile /></Guard>} />
                    <Route path="/admin/users" element={<Guard><Users /></Guard>} />
                    <Route path="/admin/customers" element={<Guard><Admin_Customers /></Guard>} />
                    <Route path="/customers" element={<Guard><Customers /></Guard>} />
                    <Route path="/edit-profile/:id" element={<Guard><Edit_Profile /></Guard>} />
                    <Route path="/edit-customer/:id" element={<Guard><Edit_Customer /></Guard>} />
                    <Route path="/edit-static/:id" element={<Guard><Edit_Static /></Guard>} />
                </Routes>
            </Router>
        </Page>
    );
};

export default AppRouter;
