import axios from "axios";
import Domains from "../../config/domains";

const backendURI = Domains.backend;

export function fetch_customers(args){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/customers/fetch`;
        const params = {
            customerId: args?.customerId, 
            userId: args?.userId, 
            email: args?.email, 
            name: args?.name,
        };
        const options = { withCredentials: true }

        axios.get(url, { params, ...options })
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function create_customer(payload){
    return new Promise((resolve, reject) => {
        console.log(payload);
        const url = `${backendURI}/customers/create`;
        const options = { withCredentials: true }
        const body = payload;

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function update_customer(query, payload, profileId){
    return new Promise((resolve, reject) => {

        const url = `${backendURI}/customers/update`;
        const options = { withCredentials: true }
        const body = {query, payload, profileId};

        axios.patch(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}


export function delete_customer(query, profileId){
    return new Promise((resolve, reject) => {
        
        const url = `${backendURI}/customers/delete`;
        const options = { withCredentials: true }
        const body = {query, profileId};

        axios.post(url, body, options)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(err)
            })

    })
}