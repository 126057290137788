import React, { useEffect, useState } from 'react'
import { fetch_profiles, update_profile } from '../../common/requests/profile';
import BrowserDatabaseKeys from '../../config/browserDatabaseKeys';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';


const authenticatedUserToken = BrowserDatabaseKeys?.sessionStorage?.user;

export default function Edit_Profile() {

  const navigate = useNavigate();

  const {id: profileId} = useParams();

  const user = sessionStorage.getItem(authenticatedUserToken) 
    ? JSON.parse(sessionStorage.getItem(authenticatedUserToken))
    : null;
  
  const [profile, setProfile] = useState(null);
  const [refetch, triggerRefetch] = useState(false);
  const [editShow, toggleEdit] = useState(null);
  const [editValues, setEditValues] = useState({
    name: null,
    username: null,
  })

  const handleUpdate = (type) => {

    const query = {_id: profile?._id};
    let payload = {};
    if ( type === "name" ) payload.name = editValues.name;
    else if ( type === "username" ) payload.username = editValues.username;

    update_profile(query, payload, profile?.userId)
      .then((res) => {
        console.log(res);
        triggerRefetch(!refetch);
      }).catch((err) => alert("Something went wrong"));
  }


  useEffect(() => {
    fetch_profiles({profileId})
    .then((res) => {
      setProfile(res.data?.profiles?.[0]);
    }).catch((err) => alert("Something went wrong"));
  },[refetch]);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h3>{profile?.email}</h3>
          <h5>{profile ? <h3></h3> : "Loading profile data"}</h5>
          {profile && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Label
                style={{ cursor: "pointer" }}
                onClick={() => toggleEdit("name")}
              >
                Full Name: {profile?.name}
              </Form.Label>
              {editShow === "name" && (
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Update name"
                    onChange={(e) =>
                      setEditValues((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleUpdate("name")}
                  >
                    Submit
                  </Button>
                </div>
              )}
              <Form.Label
                style={{ cursor: "pointer" }}
                onClick={() => toggleEdit("username")}
              >
                Username: {profile?.username}
              </Form.Label>
              {editShow === "username" && (
                <div>
                  <Form.Control
                    type="text"
                    placeholder="Update username"
                    onChange={(e) =>
                      setEditValues((prev) => ({ ...prev, username: e.target.value }))
                    }
                  />
                  <Button
                    variant="primary"
                    onClick={() => handleUpdate("username")}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
